(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientDetailController', PatientDetailController);

    PatientDetailController.$inject = ['$translate', '$window', 'entity'];

    function PatientDetailController($translate, $window, entity) {
        const vm = this;

        vm.patient = entity;

        vm.$onInit = () => {

            $translate('continuumplatformApp.patient.detail.title', {initials: entity.initials}).then(title => {
                $window.document.title = title;
            });

        };

    }
})();
