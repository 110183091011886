(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('practitioner', {
                parent: 'network',
                url: '/practitioner?page&sort&search&job',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SECURITY'],
                    pageTitle: 'continuumplatformApp.practitioner.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/practitioner/practitioners.html',
                        controller: 'PractitionerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    search: null,
                    advancedSearch: false,
                    job: {
                        value: null,
                        squash: true
                    },
                    speciality: null,
                    email: null,
                    nationalIdentifier: null,
                    firstName: null,
                    postalCode: null,
                    city: null,
                    lastName: null,
                    healthFacilityName: null,
                    validated: null,
                    userStatus: null,
                    legalStatus: null,
                    legalSubStatus: null,
                    badges: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('practitioner.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SECURITY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                        controller: 'PractitionerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    job: null,
                                    speciality: null,
                                    category: null,
                                    firstName: null,
                                    lastName: null,
                                    adeli: null,
                                    rpps: null,
                                    siret: null,
                                    phone: null,
                                    mobilePhone: null,
                                    email: null,
                                    address: null,
                                    postalCode: null,
                                    city: null,
                                    country: 'FR',
                                    legalRegistrationNumber: null,
                                    legalStatus: null,
                                    legalSubStatus: null,
                                    notes: null,
                                    userAuthType: null,
                                    emailShown: true,
                                    mobilePhoneShown: true,
                                    validated: true,
                                    notify: true
                                };
                            },
                            options: {
                                mode: 'creation'
                            }
                        }
                    }).result.then(function () {
                        $state.go('practitioner', null, {reload: 'practitioner'});
                    }, function () {
                        $state.go('practitioner');
                    });
                }]
            })
            .state('practitioner-detail', {
                parent: 'practitioner',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SECURITY'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/practitioner/practitioner-detail.html',
                        controller: 'PractitionerDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('user');
                        $translatePartialLoader.addPart('audits');
                        $translatePartialLoader.addPart('visitType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Practitioner', function ($stateParams, Practitioner) {
                        return Practitioner.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('practitioner-detail.edit', {
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_SECURITY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                        controller: 'PractitionerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Practitioner', function (Practitioner) {
                                return Practitioner.get({id: $stateParams.id}).$promise;
                            }],
                            options: {
                                mode: 'edition'
                            },
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('practitioner-detail.delete', {
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SECURITY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-delete-dialog.html',
                        controller: 'PractitionerDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Practitioner', function (Practitioner) {
                                return Practitioner.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('practitioner', null, {reload: 'practitioner'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
