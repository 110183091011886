(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyDetailController', PatientTherapyDetailController);

    PatientTherapyDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', '$interval', '$filter', '$uibModal', '$window', 'entity', 'PatientTherapy', 'Patient', 'Practitioner', 'HealthFacility', 'Therapy', 'Visit', 'moment', 'Principal'];

    function PatientTherapyDetailController($scope, $rootScope, $state, $stateParams, $timeout, $interval, $filter, $uibModal, $window, entity, PatientTherapy, Patient, Practitioner, HealthFacility, Therapy, Visit, moment, Principal) {
        const vm = this;

        vm.patientTherapy = entity;

        // load basic patient data - will be overridden with full data
        vm.patient = vm.patientTherapy.patient;

        $window.document.title = entity.programName + ' #' + entity.id;

        vm.start = start;
        vm.cancel = cancel;
        vm.edit = edit;
        vm.shift = shift;
        vm.pause = pause;
        vm.resume = resume;
        vm.stop = stop;
        vm.deletePatientTherapy = deletePatientTherapy;

        vm.$onInit = function () {
            loadPatient();
            loadTherapyAndVisits();
        };

        Principal.identity().then(function (account) {
            vm.isApprobator = account.practitioner.job === 'J10' && vm.patientTherapy.doctor.id === account.practitioner.id;
        });

        function loadPatient() {
            Patient.get({id: vm.patientTherapy.patient.id}, patient =>
                vm.patient = patient);
        }

        function loadTherapyAndVisits() {
            Promise.all([loadTherapy(), loadVisits()]).then(() => {
                if (vm.therapy && vm.therapy.singleRenewed) {
                    if (['S20_ACTIVE', 'S30_PAUSED'].includes(vm.patientTherapy.status)) {
                        vm.singleRenewed = vm.visits.find(v => v.frequency && v.renewalAtExpiration && ['TODO', 'IN_PROGRESS', 'PAUSED'].includes(v.status));
                    } else if (vm.therapy && vm.therapy.plan.visits && vm.therapy.plan.visits.length === 1) {
                        vm.singleRenewed = vm.therapy.plan.visits[0];
                        if (vm.singleRenewed && vm.patientTherapy.singleOverride) {
                            if (vm.patientTherapy.singleOverride.frequency) {
                                vm.singleRenewed.frequency = vm.patientTherapy.singleOverride.frequency;
                            }
                            if (vm.patientTherapy.singleOverride.reminderDay !== null) {
                                vm.singleRenewed.reminderDay = vm.patientTherapy.singleOverride.reminderDay;
                            }
                            if (vm.patientTherapy.singleOverride.limitDays !== null) {
                                vm.singleRenewed.limitDays = vm.patientTherapy.singleOverride.limitDays;
                            }
                        }
                    }
                }
                loadAgenda();
            });
        }

        function loadAgenda() {
            // exclude single renewed from agenda
            vm.agenda = vm.visits.filter(v =>
                !vm.singleRenewed
                || !vm.singleRenewed.id
                || v.id !== vm.singleRenewed.id);
        }

        function loadTherapy() {
            if (!vm.patientTherapy.therapyId) {
                return Promise.resolve(null);
            }
            return Therapy.get({ id: vm.patientTherapy.therapyId }).$promise.then(therapy => {
                vm.therapy = therapy;
            });
        }

        function loadVisits() {
            return Visit.query({
                "patientTherapyId.equals": vm.patientTherapy.id,
                sort: ['orderingDate', 'id'],
                size: 1000 // TODO hard-coded size
            }).$promise.then(visits => {
                const today = moment();
                if(['S20_ACTIVE', 'S30_PAUSED'].includes(vm.patientTherapy.status)) {
                    vm.visits = visits.map(v => {
                        const diff = today.diff(v.orderingDate, 'days');
                        v.hiddenPast = diff > 15;
                        v.hiddenFuture = diff < -15;
                        return v;
                    });
                } else {
                    vm.visits = visits;
                }
                vm.countVisitsDone = $filter('filter')(visits, {status: 'DONE'}).length;
                return visits;
            });
        }

        vm.getNumber = function (num) {
            return new Array(num);
        };

        function start() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-start.html',
                controller: 'PatientTherapyStartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['PatientTherapy', function (PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(() => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function cancel() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-cancel.html',
                controller: 'PatientTherapyCancelController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['PatientTherapy', function (PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            }).result.then((result) => {
                PatientTherapy.get({id: vm.patientTherapy.id}, patientTherapy => vm.patientTherapy = patientTherapy);
                loadVisits().then(() => {
                    loadAgenda();
                });
            }, () => {
                // nothing
            });
        }

        function edit() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-dialog.html',
                controller: 'PatientTherapyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['PatientTherapy', (PatientTherapy) => PatientTherapy.get({id: $stateParams.id}).$promise]
                }
            }).result.then(() => {
                PatientTherapy.get({ id: vm.patientTherapy.id }, patientTherapy => {
                    vm.patientTherapy = patientTherapy;
                    loadTherapyAndVisits();
                });
            }, () => {
                // nothing
            });
        }

        function deletePatientTherapy() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-delete-dialog.html',
                controller: 'PatientTherapyDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['PatientTherapy', function (PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            }).result.then((result) => {
                $state.go('patient-detail.therapies', {id: vm.patientTherapy.patientId});
            }, () => {
                // nothing
            });
        }

        function shift() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'SHIFT_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function pause() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'PAUSE_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function resume() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'RESUME_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function stop() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'STOP_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

    }
})();
