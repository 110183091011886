(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitController', VisitController);

    VisitController.$inject = ['$state', '$rootScope', '$scope', '$stateParams', '$uibModal', 'Visit', 'PatientTherapy', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'moment', 'Principal'];

    function VisitController($state, $rootScope, $scope, $stateParams, $uibModal, Visit, PatientTherapy, ParseLinks, AlertService, paginationConstants, pagingParams, moment, Principal) {

        const vm = this;

        vm.filters = $stateParams.filters;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.today = moment().startOf('day').toDate();

        vm.onFiltersChange = onFiltersChange;
        vm.loadPage = loadPage;
        vm.plan = plan;
        vm.transition = transition;

        vm.$onInit = function() {
            if (!vm.isHospitalUser && !vm.isCoordination) {
                vm.unsubscribeVisit = $rootScope.$on('continuumplatformApp:visitUpdate', loadVisits);

                vm.unwatchFiltersStatus = $scope.$watchCollection("vm.filters.status", function (oldValue, newValue) {
                    onFiltersChange();
                });

                getAccount().then(() => {
                    loadAll();
                });
            }
        };

        vm.$onDestroy = function() {
            vm.unsubscribeVisit();
            vm.unwatchFiltersStatus();
        };

        function getAccount() {
            return Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.isCoordination = account.practitioner && account.practitioner.healthFacilityType === 'COORDINATION';
                vm.isHospitalUser = account.practitioner && account.practitioner.healthFacilityType === 'HOSPITAL';

                vm.practitioner = account.practitioner;

                vm.canDisplayFilters = vm.isAdmin || (vm.practitioner && vm.isHospitalUser);

                vm.isPharmacist = vm.practitioner && vm.practitioner.job === 'J21';
                const isHospitalPharmacist = vm.practitioner && vm.isHospitalUser && vm.practitioner.job === 'J21';
                const isHospitalPharmacyPreparer = vm.practitioner && vm.isHospitalUser && vm.practitioner.job === 'J38';
                vm.isNurse = vm.practitioner && vm.practitioner.job === 'J60';
                const isCoordinationNurse = vm.practitioner && vm.practitioner.healthFacilityType === 'HOSPITAL' && vm.practitioner.job === 'J60';

                if (vm.filters.feasibleByMe === null || angular.isUndefined(vm.filters.feasibleByMe)) {
                    vm.filters.feasibleByMe = !vm.isAdmin && !isHospitalPharmacist && !isCoordinationNurse && !isHospitalPharmacyPreparer;
                }

                vm.visitTypes = Visit.visitTypes;
                vm.visitStatuses = Visit.visitStatuses;
            });
        }

        vm.parseDate = function (str) {
            return new Date(str);
        };

        function onFiltersChange() {

            if (vm.page) {

                if (vm.page === 1) {
                    loadAll();
                } else {
                    // reset page number
                    vm.page = 1;
                    // change url
                    vm.transition();
                }

            }
        }

        function loadAll() {
            loadFilterTherapies();
            loadVisits();
        }

        function loadFilterTherapies() {
            Visit.queryDistinctTherapies({
                "therapyId.equals": vm.filters.therapyId,
                "type.equals": vm.filters.type,
                "status.in": vm.filters.status
            }, function (result) {
                vm.therapies = result;
            });
        }

        function loadVisits() {

            vm.reverse = !(vm.filters.status.length === 0
                || vm.filters.status.indexOf('DONE') !== -1
                || vm.filters.status.indexOf('CANCELED') !== -1
                || vm.filters.status.indexOf('EXPIRED') !== -1);

            Visit.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                feasibleByPractitionerId: vm.filters.feasibleByMe ? vm.practitioner.id : undefined,
                "type.equals": vm.filters.type,
                "status.in": vm.filters.status,
                "query": vm.filters.query,
                "therapyId.equals": vm.filters.therapyId,
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.visits = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function plan(visit) {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: ['Visit', function (Visit) {
                        return Visit.get({id: visit.id}).$promise;
                    }],
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

    }
})();
