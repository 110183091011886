(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyReportController', PatientTherapyReportController);

    PatientTherapyReportController.$inject = ['$state', '$filter', '$timeout', '$window', 'moment', 'PatientTherapyReport', 'ParseLinks', 'AlertService', 'DateUtils', 'Principal'];

    function PatientTherapyReportController($state, $filter, $timeout, $window, moment, PatientTherapyReport, ParseLinks, AlertService, DateUtils, Principal) {

        const vm = this;

        const colors = {
            "S11_VALIDATED": "#337ab7",
            "S20_ACTIVE": "#5bc0de",
            "S30_PAUSED": "#f0ad4e",
            "S40_FINISHED": "#5cb85c",
            "S50_STOPPED": "#d9534f",
            "S99_CANCELED": "#333333"
        };

        vm.loadAll = loadAll;
        vm.changeFacility = changeFacility;

        Principal.hasAuthority('REPORT_GLOBAL').then(function (hasAuthority) {
            vm.hasGlobalReport = hasAuthority;

            loadAll();
        });

        vm.programId = null;
        vm.therapyId = null;
        vm.healthFacilityId = null;

        function loadAll() {

            if (vm.hasGlobalReport) {
                PatientTherapyReport.queryDistinctFacilities({
                    "sort": "healthFacility.name,asc",
                    "therapyId.specified": vm.therapyId === null ? false : undefined,
                    "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId
                }, function (healthFacilities) {
                    vm.healthfacilities = healthFacilities;
                });

                // count by health facility
                if (!vm.healthFacilityId)
                    PatientTherapyReport.query({
                        sort: "countTotal,desc",
                        "indicator.equals": "count_patient_therapies",
                        "startPeriod.specified": false,
                        "status.equals": 'S15_STARTED',
                        "healthFacilityId.specified": true,
                        "therapyId.specified": vm.therapyId === null ? false : undefined,
                        "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId
                    }, onHealthFacilityTherapyCountSuccess, onError);
            }

            PatientTherapyReport.queryDistinctTherapies({
                "sort": "therapy.name",
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId
            }, function (result) {
                vm.therapies = result;
            });

            PatientTherapyReport.query({
                sort: ["startPeriod,asc"],
                "indicator.equals": "count_patient_therapies",
                "startPeriod.specified": true,
                "status.in": ['S00_CREATED', 'S15_STARTED', 'S35_ACTIVE_PAUSED'],
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId
            }, onPatientTherapyReportSuccess, onError);

            PatientTherapyReport.query({
                sort: "status",
                "indicator.equals": "count_patient_therapies",
                "startPeriod.specified": false,
                "status.specified": true,
                "status.in": ['S11_VALIDATED', 'S20_ACTIVE', 'S30_PAUSED', 'S40_FINISHED', 'S50_STOPPED', 'S99_CANCELED'],
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId
            }, onPatientTherapyStatusesSuccess, onError);

            PatientTherapyReport.query({
                "indicator.equals": "count_distinct_prescribers",
                "startPeriod.specified": false,
                "status.specified": false,
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                "prescriberId.specified": false
            }, onCountPrescribersSuccess, onError);

            PatientTherapyReport.query({
                "indicator.equals": "count_visits",
                "startPeriod.equals": moment().startOf('month').format('YYYY-MM-DD'),
                "status.specified": false,
                "therapyId.specified": vm.therapyId === null ? false : undefined,
                "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                "healthFacilityId.specified": vm.hasGlobalReport && vm.healthFacilityId === null ? false : undefined,
                "healthFacilityId.equals": vm.hasGlobalReport && vm.healthFacilityId === null ? undefined : vm.healthFacilityId
            }, onCountVisitsSuccess, onError);

            function onPatientTherapyReportSuccess(data, headers) {
                vm.reportLines = data;

                generateStartedTotalChart();
                generateActiveChart();

                const xy = [];
                xy.push(vm.reportLines
                    .reduce(function (arr, line) {
                        if (line['status'] === 'S00_CREATED')
                            arr.push({
                                x: DateUtils.convertLocalDateFromServer(line.startPeriod),
                                y: line['count']
                            });
                        return arr;
                    }, []));

                PatientTherapyReport.query({
                    size: 1,
                    sort: ["startPeriod,asc"],
                    "indicator.equals": "count_distinct_prescribers",
                    "startPeriod.specified": true,
                    "status.specified": false,
                    "therapyId.specified": vm.therapyId === null ? false : undefined,
                    "therapyId.equals": vm.therapyId === null ? undefined : vm.therapyId,
                    "healthFacilityId.specified": vm.healthFacilityId === null ? false : undefined,
                    "healthFacilityId.equals": vm.healthFacilityId === null ? undefined : vm.healthFacilityId,
                    "prescriberId.specified": false
                }, function (prescribersData) {

                    xy.push(prescribersData
                        .reduce(function (arr, line) {
                            arr.push({
                                x: DateUtils.convertLocalDateFromServer(line.startPeriod),
                                y: line['count']
                            });
                            return arr;
                        }, []));

                    vm.monthlyChart = {
                        data: xy,
                        options: {
                            maintainAspectRatio: false,
                            legend: {
                                display: true
                            },
                            tooltips: {
                                enabled: true,
                                mode: 'x',
                                intersect: false
                            },
                            hover: {
                                mode: 'x', // Show all points on x-axis
                                intersect: false
                            },
                            scales: {
                                xAxes: [{
                                    type: 'time',
                                    distribution: 'linear',
                                    display: true,
                                    scaleLabel: {
                                        display: false
                                    },
                                    ticks: {
                                        major: {
                                            fontStyle: 'bold',
                                            fontColor: '#000000'
                                        }
                                    },
                                    time: {
                                        tooltipFormat: "MMM YYYY",
                                        unit: 'month'
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: false
                                    },
                                    ticks: {
                                        suggestedMin: 0,
                                        precision: 0
                                    }
                                }]
                            }
                        },
                        datasetOverride: [
                            {
                                label: "Demandes",
                                order: 2,
                                borderWidth: 1,
                                type: 'bar',
                                backgroundColor: "rgba(60,141,188,0.8)",
                                hoverBackgroundColor: "rgb(70,189,243)"
                            },
                            {
                                label: "Prescripteurs",
                                order: 1,
                                borderWidth: 3,
                                type: 'line',
                                borderColor: "rgba(92,184,92, .8)",
                                pointBorderColor: "rgb(92,184,92)",
                            }
                        ]
                    };
                }, onError);

            }

            function generateStartedTotalChart() {
                const xy = [];
                xy.push(vm.reportLines
                    .reduce(function (arr, line) {
                        if (line['status'] === 'S15_STARTED')
                            arr.push({
                                x: DateUtils.convertLocalDateFromServer(line.startPeriod),
                                y: line['countTotal']
                            });
                        return arr;
                    }, []));
                vm.startedTotalChart = {
                    data: xy,
                    options: {
                        maintainAspectRatio: false,
                        tooltips: {
                            enabled: true,
                            mode: 'x',
                            intersect: false
                        },
                        hover: {
                            mode: 'x', // Show all points on x-axis
                            intersect: false
                        },
                        scales: {
                            xAxes: [{
                                type: 'time',
                                distribution: 'linear',
                                display: true,
                                scaleLabel: {
                                    display: false
                                },
                                ticks: {
                                    major: {
                                        fontStyle: 'bold',
                                        fontColor: '#000000'
                                    }
                                },
                                time: {
                                    tooltipFormat: "MMM YYYY",
                                    unit: 'month'
                                }
                            }],
                            yAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: false
                                },
                                ticks: {
                                    suggestedMin: 0,
                                    precision: 0
                                }
                            }]
                        }
                    },
                    datasetOverride: [
                        {
                            label: "Démarrés",
                            borderWidth: 2,
                            type: 'line'
                        }
                    ]
                };
            }

            function generateActiveChart() {
                const xy = [];
                xy.push(vm.reportLines
                    .reduce(function (arr, line) {
                        if (line['status'] === 'S35_ACTIVE_PAUSED')
                            arr.push({
                                x: DateUtils.convertLocalDateFromServer(line.startPeriod),
                                y: line['count']
                            });
                        return arr;
                    }, []));
                vm.activeChart = {
                    data: xy,
                    options: {
                        maintainAspectRatio: false,
                        tooltips: {
                            enabled: true,
                            mode: 'x',
                            intersect: false
                        },
                        hover: {
                            mode: 'x', // Show all points on x-axis
                            intersect: false
                        },
                        scales: {
                            xAxes: [{
                                type: 'time',
                                distribution: 'linear',
                                display: true,
                                scaleLabel: {
                                    display: false
                                },
                                ticks: {
                                    major: {
                                        fontStyle: 'bold',
                                        fontColor: '#000000'
                                    }
                                },
                                time: {
                                    tooltipFormat: "MMM YYYY",
                                    unit: 'month'
                                }
                            }],
                            yAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: false
                                },
                                ticks: {
                                    suggestedMin: 0,
                                    precision: 0
                                }
                            }]
                        }
                    },
                    datasetOverride: [
                        {
                            label: "File active",
                            borderWidth: 2,
                            type: 'line',
                            borderColor: "rgb(66,139,202)",
                            backgroundColor: "rgba(66,139,202, .3)",
                            pointBorderColor: "rgb(66,139,202)",
                            pointBackgroundColor: "rgb(210,237,255)",
                        }
                    ]
                };
            }

            function onHealthFacilityTherapyCountSuccess(data, headers) {
                vm.healthFacilityTherapyCountLines = data;

                vm.facilityDoughnut = {
                    labels: vm.healthFacilityTherapyCountLines
                        .reduce(function (arr, line) {
                            arr.push(
                                line['healthFacilityName']
                            );
                            return arr;
                        }, []),
                    data: vm.healthFacilityTherapyCountLines
                        .reduce(function (arr, line) {
                            arr.push(
                                line['countTotal']
                            );
                            return arr;
                        }, []),
                    options: {
                        maintainAspectRatio: false
                    }
                };
            }

            function onPatientTherapyStatusesSuccess(data, headers) {
                vm.patientTherapyStatusesLines = data;

                vm.statusChart = {
                    labels: vm.patientTherapyStatusesLines
                        .reduce(function (arr, line) {
                            arr.push(
                                $filter('translate')('continuumplatformApp.PatientTherapyStatus.' + line['status'])
                            );
                            return arr;
                        }, []),
                    data: vm.patientTherapyStatusesLines
                        .reduce(function (arr, line) {
                            arr.push(
                                line['countTotal']
                            );
                            return arr;
                        }, []),
                    options: {
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0
                                }
                            }]
                        }
                    },
                    colors: vm.patientTherapyStatusesLines
                        .reduce(function (arr, line) {
                            arr.push(
                                colors[line.status]
                            );
                            return arr;
                        }, [])
                };
            }

            function onCountPrescribersSuccess(data, headers) {
                vm.countPrescribers = data.length
                    ? data[0].countTotal
                    : 0;
            }

            function onCountVisitsSuccess(data, headers) {
                vm.countVisits = data.length
                    ? data[0].countTotal
                    : 0;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function changeFacility(hearlthFacilityId) {
            vm.healthFacilityId = hearlthFacilityId;
            loadAll();
            $timeout(() => {
                $window.document.body.scrollTop = $window.document.documentElement.scrollTop = 0;
            });
        }

    }
})();
