(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                pageTitle: 'global.menu.home'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('coordination');
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('patientTherapy');
                    $translatePartialLoader.addPart('healthFacility');
                    $translatePartialLoader.addPart('therapy');
                    $translatePartialLoader.addPart('audits');
                    $translatePartialLoader.addPart('entity-audit');
                    $translatePartialLoader.addPart('practitioner');
                    $translatePartialLoader.addPart('visit');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('user');
                    $translatePartialLoader.addPart('message');
                    $translatePartialLoader.addPart('visitType');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
