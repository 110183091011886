(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Patient', Patient);

    Patient.$inject = ['$resource', 'moment', 'DateUtils'];

    function Patient($resource, moment, DateUtils) {
        var resourceUrl = 'api/patients/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryDistinctFacilities': {url: 'api/patient/distinct-facilities', method: 'GET', isArray: true},
            'queryDistinctFacilityDoctors': {url: 'api/patient/distinct-facility-doctors', method: 'GET', isArray: true},
            'queryDistinctNurseCoords': {url: 'api/patient/distinct-nurse-coords', method: 'GET', isArray: true},
            'queryDistinctFacilityPharmacists': {url: 'api/patient/distinct-facility-pharmacists', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        // compute age
                        if (data.birthDate)
                            data.age = moment().diff(data.birthDate, 'years');

                        data.birthDate = DateUtils.convertLocalDateFromServer(data.birthDate);
                        data.deathDate = DateUtils.convertLocalDateFromServer(data.deathDate);
                    }
                    return data;
                }
            },
            'updateIdentity': {
                url: 'api/patients/:id/identity',
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
                    copy.deathDate = DateUtils.convertLocalDateToServer(copy.deathDate);
                    return angular.toJson(copy);
                }
            },
            'updateAddress': {
                url: 'api/patients/:id/address',
                method: 'PUT'
            },
            'updateUser': {
                url: 'api/patients/:id/user',
                method: 'PUT'
            },
            'updateDetails': {
                url: 'api/patients/:id/details',
                method: 'PUT'
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
                    copy.deathDate = DateUtils.convertLocalDateToServer(copy.deathDate);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = {id: data};
                    }
                    return data;
                }
            }
        });
    }
})();
