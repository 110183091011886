(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('NotificationSettingsController', NotificationSettingsController);

    NotificationSettingsController.$inject = ['$scope', 'Account'];

    function NotificationSettingsController($scope, Account) {
        var vm = this;

        vm.isSaving = false;
        vm.success = null;
        vm.error = null;
        vm.save = save;

        // Permet de générer l'affichage de la partie "suivi clinique"
        vm.visitsClinic = [
            {type: 'PRO', label: 'Auto-évaluation', description: 'Questionnaire renseigné par la patient ou son aidant'},
        ];

        Account.getNotificationSettings().$promise.then(function (result) {
            vm.notificationSettings = result;
            $scope.$watch("vm.notificationSettings", save, true);
        });

        function save(newSettings, oldSettings) {
            if (!angular.equals(newSettings, oldSettings)) { // prevent running on init
                vm.isSaving = true;
                Account.updateNotificationSettings(newSettings).$promise.then(function () {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = 'ERROR';
                    vm.notificationSettings = oldSettings;
                });
            }
        }
    }
})();
