(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyHistoryController', PatientTherapyHistoryController);

    PatientTherapyHistoryController.$inject = ['$translate', '$window', '$state', '$uibModal', 'entity', 'PatientTherapy', 'Coordination', 'Principal'];

    function PatientTherapyHistoryController($translate, $window, $state, $uibModal, entity, PatientTherapy, Coordination, Principal) {
        const vm = this;

        vm.patient = entity;

        vm.newRequest = newRequest;

        vm.$onInit = function () {
            setTitle();
            loadAccount();
            loadTherapies();
        };

        vm.$onDestroy = function () {

        };

        function loadAccount() {
            Principal.hasAuthority('PATIENT_THERAPY_MANAGE')
                .then(hasAuthority => vm.hasPatientTherapyManagementAuthority = hasAuthority);
        }

        function setTitle() {
            $translate('continuumplatformApp.patient.coordination.title', {initials: entity.initials}).then(title => {
                $window.document.title = title;
            });
        }

        function loadTherapies() {
            return PatientTherapy.query({
                "patientId.equals": vm.patient.id,
                "sort": ['startDate,desc', 'id,desc']

            }).$promise.then((patientTherapies) => {
                vm.patientTherapies = patientTherapies;
                vm.patientTherapyIds = patientTherapies.map((therapy) => therapy.id);
                return vm.patientTherapies;
            });
        }

        function newRequest() {
            Coordination.newRequest(vm.patient.id);
        }

    }
})();
