(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('HDJ', HDJ);

    HDJ.$inject = ['$rootScope', '$http'];

    function HDJ($rootScope, $http) {

        return {query};

        function query(params) {
            return $http.get('api/hdj/planning', {params});
        }

    }

})();
