(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitShiftDialogController', VisitShiftDialogController);

    VisitShiftDialogController.$inject = ['$scope', '$timeout', '$uibModal', '$uibModalInstance', 'visit', 'PatientTherapy'];

    function VisitShiftDialogController($scope, $timeout, $uibModal, $uibModalInstance, visit, PatientTherapy) {
        const vm = this;

        const initialPlannedDate = moment(visit.plannedDate || visit.dueDate || visit.limitDate).startOf('day');

        vm.newPlannedDate = visit.plannedDate || visit.dueDate || visit.limitDate;
        vm.today = new Date();
        vm.days = null;
        vm.isShifting = false;

        $timeout(function () {
            angular.element('#field_plannedDate').focus();
        });

        $scope.$watch('vm.newPlannedDate', (newValue, oldValue) => {
            if (newValue) {
                const newDate = moment(newValue).startOf('day');
                vm.days = moment.duration(newDate.diff(initialPlannedDate)).days();
            } else {
                vm.days = null;
            }
        });

        vm.shift = () => {
            vm.isShifting = true;
            PatientTherapy.shift(
                {id: visit.patientTherapyId},
                {
                    shiftDays: vm.days,
                    from: initialPlannedDate,
                })
                .$promise
                .then(() => {
                    $scope.$emit('continuumplatformApp:visitUpdate', visit.id);
                    $uibModalInstance.close();
                    // TODO générer un pré-injection quand c'est nécessaire !
                })
                .catch(() => {
                    // do nothing
                })
                .finally(() => {
                    vm.isShifting = false;
                });
        };

        vm.dismiss = () => {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
