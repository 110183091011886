(function () {
    'use strict';

    var visitPrerequisites = {
        bindings: {
            visit: '='
        },
        controller: VisitPrerequisitesController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-prerequisites.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitPrerequisites', visitPrerequisites);

    VisitPrerequisitesController.$inject = ['$uibModal', '$scope', '$state', '$sce', 'Lightbox', 'Visit', 'PatientTherapy'];

    function VisitPrerequisitesController($uibModal, $scope, $state, $sce, Lightbox, Visit, PatientTherapy) {

        const vm = this;

        vm.$onInit = () => {
            load();

            $scope.$watch('vm.visit', (newVal, oldVal) => {
                if (newVal && newVal !== oldVal) {
                    load();
                }
            });
        };

        vm.openPractitioner = practitioner => {
            $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                controller: 'PractitionerModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    practitioner: () => practitioner
                }
            });
        };

        function load() {
            loadPatientTherapy();
            loadPreviousVisit();
        }

        function loadPatientTherapy() {
            PatientTherapy.get({id: vm.visit.patientTherapyId}).$promise.then(patientTherapy => {
                vm.patientTherapy = patientTherapy;
            });
        }

        function loadPreviousVisit() {
            vm.hasDone = null;
            vm.lastPreviousDoneVisit = null;
            vm.previousTodoVisits = null;
            Visit.query({
                'id.notEquals': vm.visit.id,
                'patientId.equals': vm.visit.patientId,
                'date.greaterThan': moment(vm.visit.plannedDate).subtract(7, 'day').toDate(),
                'date.lessThan': vm.visit.plannedDate,
                sort: 'date,desc',
                size: 1
            }).$promise.then(done => {
                vm.hasDone = done.length === 1;
                if (vm.hasDone) {
                    vm.lastPreviousDoneVisit = done[0];
                    // récupération d'un éventuel questionnaire à renseigner après le dernier renseigné
                    Visit.query({
                        'id.notEquals': vm.visit.id,
                        'patientId.equals': vm.visit.patientId,
                        'plannedOrLimitOrDueDate.greaterThan': vm.lastPreviousDoneVisit.date,
                        'plannedOrLimitOrDueDate.lessThan': vm.visit.limitDate || vm.visit.plannedDate,
                        'status.notEquals': 'DONE',
                        sort: 'plannedOrLimitOrDueDate,desc',
                        size: 3
                    }).$promise.then(mapPreviousTodoVisits);
                } else {
                    Visit.query({
                        'id.notEquals': vm.visit.id,
                        'patientId.equals': vm.visit.patientId,
                        'plannedOrLimitOrDueDate.greaterThan': moment(vm.visit.plannedDate).subtract(7, 'day').toDate(),
                        'plannedOrLimitOrDueDate.lessThan': vm.visit.limitDate || vm.visit.plannedDate,
                        'status.notEquals': 'DONE',
                        'patientTherapyStatus.in': ['S20_ACTIVE', 'S30_PAUSED'],
                        sort: 'plannedOrLimitOrDueDate,desc',
                        size: 3
                    }).$promise.then(mapPreviousTodoVisits);
                }
            });
        }

        function mapPreviousTodoVisits(previousVisits) {
            vm.previousTodoVisits = previousVisits.map(previous => {
                previous.warn = new Date(previous.plannedDate || previous.limitDate || previous.dueDate) > vm.visit.plannedDate;
                return previous;
            });
        }

    }

})();
