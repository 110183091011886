(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PendingVisitsController', PendingVisitsController);

    PendingVisitsController.$inject = ['$filter', '$scope', '$state', '$log', '$uibModal', 'filters', 'Principal', 'Visit', 'HealthFacility', 'Practitioner', 'HDJ'];

    function PendingVisitsController($filter, $scope, $state, $log, $uibModal, filters, Principal, Visit, HealthFacility, Practitioner, HDJ) {

        const vm = this;

        vm.filters = filters;
        vm.loading = true;

        vm.$onInit = () => {

            Principal.identity()
                .then((account) => {

                    let promises = [];
                    if (account.practitioner && account.practitioner.healthFacilityId) {
                        promises.push(loadFacilities(account));
                    } else {
                        promises.push(Promise.resolve());
                    }
                    Promise.all(promises).then(() => {
                        loadVisits();
                    });

                });

        };

        vm.$onDestroy = () => {
            if (vm.unwatchAssigneeId) {
                vm.unwatchAssigneeId();
            }
        };

        vm.applyFromDate = loadVisits;

        function loadFacilities(account) {
            return HealthFacility.query({
                view: 'summary',
                'healthFacilityId.equals': account.practitioner.healthFacilityId,
                size: 99 // TODO hard-coded size
            }).$promise.then(facilities => {
                if (facilities.length > 0) {
                    vm.facilities = facilities;

                    if (account.settings.injectionAssigneeId) {
                        vm.filters.assigneeId = account.settings.injectionAssigneeId;
                    } else {
                        vm.filters.assigneeId = undefined;
                    }

                    vm.unwatchAssigneeId = $scope.$watch('vm.filters.assigneeId', onChangeAssigneeId);
                }

            });
        }

        function onChangeAssigneeId(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            storeAssigneeId(newVal);
            loadVisits();
        }

        function storeAssigneeId(value) {
            if (value)
                Principal.updateSetting('injectionAssigneeId', value);
            else
                Principal.deleteSetting('injectionAssigneeId');
        }

        function loadVisits() {
            vm.loading = true;
            vm.visits = null;
            HDJ.query({
                fromDate: moment(vm.filters.fromDate).format('YYYY-MM-DD'),
                types: types(),
                assigneeId: vm.filters.assigneeId ? vm.filters.assigneeId : undefined
            }).then((response) => {
                onSuccess(response.data, response.headers);
            }).finally(() => {
                vm.loading = false;
            });
        }

        function types() {
            if (vm.filters.therapyType === 'hdj') {
                return ['HOSPITAL'];
            }
            if (vm.filters.therapyType === 'hah') {
                return ['HAH'];
            }
            return undefined;
        }

        function onSuccess(data, headers) {
            vm.visits = data;

            vm.dates = $filter('groupBy')(vm.visits
                .map(visit => {
                    visit.plannedDateString = moment(visit.plannedDate).format('YYYY-MM-DD');
                    return visit;
                }), 'plannedDateString');
        }

    }
})();
