(function() {
    /*jshint bitwise: false*/
    'use strict';

    // eslint-disable-next-line angular/no-service-method
    angular
        .module('continuumplatformApp')
        .service('DashboardService', DashboardService);

    DashboardService.$inject = ['$state', '$log', 'Principal', 'Message', 'MessageTo', 'Visit', 'PatientTherapy'];

    function DashboardService($state, $log, Principal, Message, MessageTo, Visit, PatientTherapy) {

        this.loadNewMessages = loadNewMessages;
        this.loadHdj = loadHdj;
        this.loadFollowUpToStart = loadFollowUpToStart;
        this.loadSuspendedFollowUp = loadSuspendedFollowUp;

        this.createUiScrollDatasource = createUiScrollDatasource;

        function loadNewMessages(successCallback, filters, page, size) {
            return Principal.identity().then(account => {
                if (!account.practitioner) {
                    return Promise.reject("Réservé aux professionnels");
                }
                if (account.practitioner.healthFacilityType === 'HOSPITAL' && !filters.facilityDoctorId) {
                    return Message.query({
                        sort: ["date,desc"],
                        "readByFacility.equals": false,
                        page,
                        size
                    }, (messages, headers) => successCallback(mapMessages(messages), headers)).$promise;
                } else if(account.practitioner.healthFacilityType === 'COORDINATION' && !filters.coordinationPractitionerId) {
                    return Promise.reject("select-unicorn");
                }
                return MessageTo.query({
                    sort: ["message.date,desc"],
                    "readDate.specified": false,
                    "practitionerId.equals": filters.facilityDoctorId || filters.coordinationPractitionerId || account.practitioner.id,
                    page,
                    size
                }, (messageTos, headers) => successCallback(mapMessageTos(messageTos), headers)).$promise;
            });
        }

        function mapMessages(messages) {
            return messages.map(message =>
                ({
                    message,
                    byLabelKeys: byLabelKeys(message),
                })
            );
        }

        function mapMessageTos(messageTos) {
            return messageTos.map(messageTo => {
                messageTo.byLabelKeys = byLabelKeys(messageTo.message);
                return messageTo;
            });
        }

        function byLabelKeys(message) {
            const byLabelKeys = [];
            if(message.author) {
                byLabelKeys.push('continuumplatformApp.practitioner.job.' + message.author.job);
                if(message.author.healthFacilityType) {
                    byLabelKeys.push('continuumplatformApp.practitioner.facilityTypeQualifier.' + message.author.healthFacilityType);
                }
            } else {
                byLabelKeys.push('continuumplatformApp.patient.teamRole.PATIENT');
            }
            return byLabelKeys;
        }

        function loadHdj(successCallback, filters) {
            return Visit.query({
                size: 1000,
                'prescriberId.equals': filters.facilityDoctorId || undefined,
                'status.in': ['PENDING'],
                'orderingDate.greaterThanOrEqual': moment().startOf('day').toISOString(),
                'orderingDate.lessThan': moment().endOf('day').add(7, 'days').toISOString(),
                sort: ['orderingDate,asc', 'id,asc'],
            }).$promise.then(successCallback);
        }

        function loadFollowUpToStart(filters) {
            return PatientTherapy.query({
                "assigneeId.equals": filters.coordinationPractitionerId || undefined,
                "status.equals": 'S11_VALIDATED',
                size: 100,
            }).$promise;
        }

        function loadSuspendedFollowUp(successCallback, filters, page, size) {
            return PatientTherapy.query({
                page,
                size,
                sort: 'pauseDate,desc',
                "status.equals": 'S30_PAUSED',
                "assigneeId.equals": filters.coordinationPractitionerId || undefined,
                "doctorId.equals": filters.facilityDoctorId || undefined,
            }, successCallback).$promise;
        }

        function createUiScrollDatasource(fetchDataFonction, pageSize, initFilter, initSortProperty, initSortOrder) {

            const datasource = {
                get: getData,
                adapter: {},
                data: [],
                loading: false,
                currentPage: 0,
                filters: initFilter || {},
                sortProperty: initSortProperty || [],
                sortOrder: initSortOrder || [],
                reloadDatasource: reloadDatasource,
                removeData: removeData,
                shouldLoadMoreData: shouldLoadMoreData,
            };

            return datasource;

            /**
             * Retrieves data based on the specified index and count.
             *
             * @param {number} index - The starting index of the data.
             * @param {number} count - The number of items to retrieve.
             * @param {function} success - The callback function to execute after retrieving the data successfully.
             *
             * @return {void}
             */
            function getData(index, count, success) {
                datasource.loading = true;

                if (datasource.shouldLoadMoreData(index, count)) {
                    loadMoreData()
                        .then(() => {
                            datasource.error = null;
                            processQueueData(index, count, success);
                        })
                        .catch(error => {
                            datasource.error = error;
                            datasource.adapter.isLoading = false;
                        })
                        .finally(() => {
                            datasource.loading = false;
                        });

                } else {
                    processQueueData(index, count, success);
                    datasource.loading = false;
                }
            }

            /**
             * Determines whether more data should be loaded based on the current item index and item count.
             *
             * @param {number} itemIndex - The index of the current item.
             * @param {number} itemCount - The total count of items.
             * @return {boolean} - Returns true if more data should be loaded, false otherwise.
             */
            function shouldLoadMoreData(itemIndex, itemCount) {
                return itemIndex >= datasource.data.length - itemCount;
            }

            /**
             * Loads more data for the active queue.
             *
             * @returns {Promise} A promise that resolves when the data is successfully loaded.
             */
            function loadMoreData() {
                return fetchDataFonction(successCallback, datasource.filters, datasource.currentPage, pageSize, datasource.sortProperty, datasource.sortOrder);
            }

            function successCallback(data, headers) {
                datasource.data = datasource.data.concat(data);
                if (angular.isFunction(headers)) {
                    datasource.totalItems = headers('X-Total-Count');
                } else {
                    datasource.totalItems = data.length;
                }
                datasource.currentPage++;
            }

            /**
             * Processes queue data and notifies success.
             *
             * @param {number} itemIndex - The index of the item being processed.
             * @param {number} itemCount - The total number of items in the queue.
             * @param {function} notifySuccess - The function to be called after processing the queue data.
             *
             * @return {void}
             */
            function processQueueData(itemIndex, itemCount, notifySuccess) {
                const resultArray = getResultArray(itemIndex, itemCount);
                notifySuccess(resultArray);
            }

            /**
             * Returns an array from activeQueue for given start index and length
             *
             * @param {number} startIndex - Start index for the slice operation
             * @param {number} length - Number of elements to slice
             *
             * @return {Array} - Results array
             */
            function getResultArray(startIndex, length) {

                const sliceStart = startIndex < 0 ? 0 : startIndex;
                const sliceEnd = startIndex + length;

                return datasource.data.slice(sliceStart, sliceEnd);
            }

            /**
             * Reloads the datasource.
             */
            function reloadDatasource() {
                datasource.data = [];
                datasource.currentPage = 0;

                if (datasource.adapter.reload) {
                    let reloadIndex = parseInt(datasource.reloadIndex);
                    reloadIndex = isNaN(reloadIndex) ? 0 : reloadIndex;
                    datasource.adapter.reload(reloadIndex);
                }
            }

            /**
             * Removes the specified data from the datasource.
             * @param dataToRemove the data to remove from the datasource
             */
            function removeData(dataToRemove) {

                // on récupère l'index de l'élément afficher en premier
                const topVisible = datasource.adapter.topVisible;
                const indexTopVisible = datasource.data.indexOf(topVisible);

                // on supprime l'élément de la liste des éléments à afficher
                const indexElementToRemove = datasource.data.indexOf(dataToRemove);
                datasource.data.splice(indexElementToRemove, 1);

                // on recharge les données en se replaçant sur l'index du premier élément qui était visible
                if (datasource.adapter.reload) {
                    datasource.adapter.reload(indexTopVisible);
                }
            }

        }
    }
})();
