(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyCancelController', PatientTherapyCancelController);

    PatientTherapyCancelController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', 'entity', 'PatientTherapy'];

    function PatientTherapyCancelController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, entity, PatientTherapy) {
        var vm = this;

        vm.patientTherapy = entity;

        vm.patientTherapy.stopDate = new Date();

        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            PatientTherapy.cancel({
                id: vm.patientTherapy.id
            }, {
                stopDate: vm.patientTherapy.stopDate,
                cancelCause: vm.patientTherapy.cancelCause,
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:patientTherapyUpdate');
            $scope.$emit('continuumplatformApp:visitUpdate');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
