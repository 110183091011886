(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('TherapyEditionController', TherapyEditionController);

    TherapyEditionController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Therapy', 'Program', 'Visit'];

    function TherapyEditionController($timeout, $scope, $state, $stateParams, entity, Therapy, Program, Visit) {
        var vm = this;

        vm.therapy = entity;
        vm.cancel = cancel;
        vm.save = save;

        vm.addVisit = addVisit;
        vm.removeVisit = removeVisit;

        vm.onChangeLimitDays = onChangeLimitDays;

        vm.visitTypes = Visit.visitTypes;

        Visit.getSteps().then(steps => vm.visitSteps = steps);

        vm.visitFrequencies = Visit.getFrequencies();

        vm.programs = Program.query({
            sort: "name",
            size: 1000 // TODO hard-coded size
        });

        vm.$onInit = () => {
            $timeout(() => {
                angular.element('#field_program').focus();
            });
        };

        function cancel() {
            if (vm.therapy.id)
                $state.go('therapy-detail', {id: vm.therapy.id});
            else
                $state.go('therapy');
        }

        function save() {
            vm.isSaving = true;
            if (vm.therapy.id) {
                Therapy.update(vm.therapy, onSaveSuccess, onSaveError);
            } else {
                Therapy.save(vm.therapy, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $state.go('therapy-detail', {id: result.id}, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function removeVisit(index) {
            if (vm.therapy.plan && vm.therapy.plan.visits) {
                vm.therapy.plan.visits.splice(index, 1);
            }
        }

        function addVisit() {
            if (!vm.therapy.plan)
                vm.therapy.plan = {};
            if (!vm.therapy.plan.visits) {
                vm.therapy.plan.visits = [];
            }
            vm.therapy.plan.visits.push({
                frequency: null,
                type: null,
                relativeDueDateDays: null,
                limitDays: null,
                steps: []
            });
            $scope.$emit('continuumplatformApp:showValidation');
        }

        function onChangeLimitDays(visit) {
            if (!visit.limitDays) {
                visit.renewalAtExpiration = false;
            }
        }
    }
})();
