(function() {
    'use strict';

    const visitDetailQolFactIcm = {
        bindings: {
            data: '=',
        },
        controller: VisitDetailQolFactIcmController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/steps/fact-icm/visit-detail-qol-fact-icm.html',
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailQolFactIcm', visitDetailQolFactIcm);

    VisitDetailQolFactIcmController.$inject = ['Principal', 'FactIcmService'];

    function VisitDetailQolFactIcmController(Principal, FactIcmService) {

        const vm = this;

        vm.round = Math.round;

        vm.$onInit = function() {
            loadAccount();

            vm.questions = [...FactIcmService.getQuestionsGroupByGroup()];

            const flatQuestions = vm.questions.flat();
            for (let i = 0; i < flatQuestions.length; i++) {
                flatQuestions[i].score = vm.data[flatQuestions[i].id];
            }
        };

        vm.$onDestroy = function() {
        };

        function loadAccount() {
            Principal.identity().then(account => {
                vm.isPractitioner = account && account.authorities.includes('ROLE_PRACTITIONER');
            });
        }

    }

})();
