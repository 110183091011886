(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientEditIdentityController', PatientEditIdentityController);

    PatientEditIdentityController.$inject = ['$timeout', '$uibModalInstance', 'entity', 'Patient'];

    function PatientEditIdentityController($timeout, $uibModalInstance, entity, Patient) {
        var vm = this;

        vm.patientId = entity.id;

        vm.healthFacilityName = entity.healthFacilityName;

        vm.form = {
            birthName: entity.birthName,
            lastName: entity.lastName,
            firstName: entity.firstName,
            deceased: entity.deceased,
            deathDate: entity.deathDate,
        };

        vm.cancel = cancel;
        vm.save = save;

        vm.notify = false;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (!vm.form.deceased) {
                vm.form.deathDate = null;
            }
            Patient.updateIdentity({
                id: vm.patientId
            }, vm.form, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
