(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('HealthFacility', HealthFacility);

    HealthFacility.$inject = ['$resource'];

    function HealthFacility($resource) {
        var resourceUrl = 'api/health-facilities/:id';

        function transformRequest(data) {
            var copy = angular.copy(data);
            if (copy.siret === '')
                copy.siret = null;
            if (copy.finessEt === '')
                copy.finessEt = null;
            if (copy.finessEj === '')
                copy.finessEj = null;
            if (copy.ssn === '')
                copy.ssn = null;
            if (copy.pvEmail === '')
                copy.pvEmail = null;
            if (copy.pvEmailDaily === '')
                copy.pvEmailDaily = null;
            if (copy.pvEmailMonthly === '')
                copy.pvEmailMonthly = null;
            if (copy.pvEmailQuarterly === '')
                copy.pvEmailQuarterly = null;
            return angular.toJson(copy);
        }

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPvCenters': {
                method: 'GET',
                params:{
                    view: 'summary',
                    "type.equals": 'PV',
                    sort: 'name,asc'
                },
                isArray: true
            },
            'update': {
                method: 'PUT',
                transformRequest: transformRequest
            },
            'updateEmail': {
                url: 'api/health-facilities/:id/email',
                method: 'PUT'
            },
            'save': {
                method: 'POST',
                transformRequest: transformRequest
            },
        });

        service.getTypes = () => [
            'HOSPITAL',
            'HAH',
            'NURSING_HOME',
            'NURSING',
            'ANALYSIS_LABORATORY',
            'PHARMACY',
            'PV',
            'COORDINATION',
            'PHARM_LAB',
            'PUBLIC_ADMIN'
        ];

        return service;

    }
})();
