(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Visit', Visit);

    Visit.$inject = ['$resource', '$translate', 'Principal', 'DateUtils', 'Practitioner'];

    function Visit($resource, $translate, Principal, DateUtils, Practitioner) {
        var resourceUrl = 'api/visits/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: transformReponse
            },
            'getQuestions': {
                url: 'api/visits/:id/questions',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'save': {
                url: 'api/visits',
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        return {id: data};
                    }
                    return null;
                }
            },
            'plan': {
                url: 'api/visits/:id/plan',
                method: 'PUT'
            },
            'confirm': {
                url: 'api/visits/:id/confirm',
                method: 'PUT'
            },
            'undoConfirm': {
                url: 'api/visits/:id/undo-confirm',
                method: 'PUT'
            },
            'start': {
                url: 'api/visits/:id/start',
                method: 'PUT',
                transformResponse: transformReponse
            },
            'draft': {
                url: 'api/visits/:id/draft',
                method: 'PUT',
            },
            'finish': {
                url: 'api/visits/:id/finish',
                method: 'PUT',
            },
            'abort': {
                url: 'api/visits/:id/abort',
                method: 'PUT',
            },
            'reply': {
                url: 'api/visits/:id/reply',
                method: 'POST'
            },
            'cancel': {
                url: 'api/visits/:id/cancel',
                method: 'POST'
            },
            'acknowledge': {
                url: 'api/visits/:id/acknowledge',
                method: 'POST'
            },
            'rename': {
                url: 'api/visits/:id/rename',
                method: 'POST'
            },
            'comment': {
                url: 'api/visits/:id/comment',
                method: 'POST'
            },
            'reopen': {
                url: 'api/visits/:id/reopen',
                method: 'POST',
            },
            'queryDistinctTherapies': {
                url: 'api/visits/distinct-therapies',
                method: 'GET',
                isArray: true
            },
            'queryDistinctFacilityDoctors': {
                url: 'api/visits/distinct-facility-doctors',
                method: 'GET',
                isArray: true
            },
            'exportPdf': {
                url: 'api/visits/:id/export',
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf',
                },
                transformResponse:
                    function (data, headers) {
                        return {
                            data: new Blob([data], {type: 'application/pdf'}),
                            headers: headers
                        };
                    }
            }
        });


        service.getFrequencies = function () {
            return [
                'DAILY',
                'WEEKLY',
                'EVERY2WEEKS',
                'EVERY3WEEKS',
                'EVERY4WEEKS',
            ];
        };

        service.availableMembers = function (type, team, patient) {
            return new Promise((resolve, reject) => {

                const additionalTeamMemberPromise = [];

                let availableMembers = team.filter(m => {
                    switch (type) {

                    case 'HOSPITAL':
                        return 'HOSPITAL' === m.role;

                        case 'HAH':
                            return ['HOSPITAL', 'HAH'].includes(m.role);

                    default:
                        return true;
                    }
                });

                // on ajoute les professionals de santé additionel à la liste des membres possibles
                // on dédoublonne et on trie le résultat avant de le retourner.
                Promise.all(additionalTeamMemberPromise).then((additionalTeamMember) => {
                    availableMembers = availableMembers.concat(additionalTeamMember.flat());

                    const membersMap = new Map();
                    availableMembers.forEach((m) => {
                        if (!membersMap.get(m.entity.id)) {
                            membersMap.set(m.entity.id, m);
                        }
                    });
                    resolve([...membersMap.values()].sort((m1, m2) => m1.role.localeCompare(m2.role)));
                });
            });

        };

        function getAdditionalTeamMember(jobs, role, healthFacilityId) {
            return Practitioner.query({'job.in': jobs, 'healthFacilityId.equals': healthFacilityId})
                .$promise
                .then((pList) => {
                    return pList.map((practitioner) => ({
                        role: role,
                        type: "Practitioner",
                        entity: practitioner
                    }));
                });
        }


        service.visitTypes = [
            'PRO',
            'HOSPITAL',
            'HAH',
        ];

        service.visitStatuses = [
            'PAUSED',
            'PENDING',
            'CONFIRMED',
            'TODO',
            'IN_PROGRESS',
            'DONE',
            'CANCELED',
            'EXPIRED'
        ];

        service.getSteps = function() {

            const codes = [
                'TEST',
                'QOL_FACT_ICM',
            ];

            const keys = codes.map(function(code) {
                return "continuumplatformApp.visit.steps." + code;
            });

            return $translate(keys).then(function (translations) {
                return codes.map(function(code) {
                    return ({
                        code: code,
                        label: translations["continuumplatformApp.visit.steps." + code]
                    });
                });
            }, function (translationIds) {
                //?
            });
        };

        return service;

        function transformReponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data.dueDate = DateUtils.convertDateTimeFromServer(data.dueDate);
                data.plannedDate = DateUtils.convertDateTimeFromServer(data.plannedDate);
                data.limitDate = DateUtils.convertDateTimeFromServer(data.limitDate);
                data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                data.date = DateUtils.convertDateTimeFromServer(data.date);
                data.nextReminderDate = DateUtils.convertDateTimeFromServer(data.nextReminderDate);
                data.ackDate = DateUtils.convertDateTimeFromServer(data.ackDate);
                data.confirmDate = DateUtils.convertDateTimeFromServer(data.confirmDate);
            }
            return data;
        }


    }
})();
