(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PatientTherapyReport', PatientTherapyReport);

    PatientTherapyReport.$inject = ['$resource', 'DateUtils'];

    function PatientTherapyReport($resource, DateUtils) {
        var resourceUrl = 'api/patient-therapy-report/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'queryDistinctFacilities': {url: 'api/patient-therapy-report/distinct-facilities', method: 'GET', isArray: true},
            'queryDistinctTherapies': {url: 'api/patient-therapy-report/distinct-therapies', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startPeriod = DateUtils.convertLocalDateFromServer(data.startPeriod);
                    }
                    return data;
                }
            }
        });
    }
})();
