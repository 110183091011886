(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Practitioner', Practitioner);

    Practitioner.$inject = ['$resource', '$translate', 'DateUtils'];

    function Practitioner($resource, $translate, DateUtils) {

        var resourceUrl = 'api/practitioners/:id';

        function transformRequest(data) {
            var copy = angular.copy(data);
            if (copy.birthDate)
                copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
            return angular.toJson(copy);
        }

        function transformResponse (data) {
            if (data) {
                data = angular.fromJson(data);
                data.birthDate = DateUtils.convertLocalDateFromServer(data.birthDate);
            }
            return data;
        }

        function transformMutationResponse(data) {
            if (data) {
                data = {id: data};
            }
            return data;
        }

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse
            },
            'getReplaces': {
                url: 'api/practitioners/:id/replaces',
                method: 'GET',
                isArray: true
            },
            'addReplaces': {
                url: 'api/practitioners/:id/replaces',
                method: 'POST'
            },
            'removeReplaces': {
                url: 'api/practitioners/:id/replaces/:replacesId',
                method: 'DELETE'
            },
            'getReplacedBy': {
                url: 'api/practitioners/:id/replaced-by',
                method: 'GET',
                isArray: true
            },
            'addReplacedBy': {
                url: 'api/practitioners/:id/replaced-by',
                method: 'POST'
            },
            'removeReplacedBy': {
                url: 'api/practitioners/:id/replaced-by/:byId',
                method: 'DELETE'
            },
            'checkAlreadyExists': {
                url: 'api/practitioners/check-already-exists',
                method: 'POST'
            },
            'sendInvitationEmail': {
                url: 'api/practitioners/send-invitation-email',
                method: 'POST'
            },
            'update': {
                url: 'api/practitioners',
                method: 'PUT',
                transformRequest,
                transformResponse: transformMutationResponse
            },
            'updateHealthFacility': {
                url: 'api/practitioners/:id/healthFacility',
                method: 'PUT'
            },
            'save': {
                method: 'POST',
                transformRequest,
                transformResponse: transformMutationResponse
            },
            'addBadge': {
                url: 'api/practitioners/:id/badges',
                method: 'POST'
            },
            'removeBadge': {
                url: 'api/practitioners/:id/badges/:badge',
                method: 'DELETE'
            },
            'selfRegister': {
                url: 'api/practitioners/self-registration',
                method: 'POST',
                transformRequest,
                transformResponse
            },
            'selfUpdate': {
                url: 'api/practitioners/self-update',
                method: 'POST',
                transformRequest,
                transformResponse
            },
            'absenceUpdate': {
                url: 'api/practitioners/absence-update',
                method: 'POST',
            }
        });

        service.getJobs = function() {
            return [
                'J10',
                'J21',
                'J38',
                'J60',
                'J99'
            ];
        };

        service.getCategories = function() {
            return [
                'M',
                'C',
                'F',
                'E'
            ];
        };

        service.getSpecialities = function () {

            var codes = [

                'SI01',
                'SI02',
                'SI03',
                'SI04',

                'SM01',
                'SM02',
                'SM03',
                'SM04',
                'SM05',
                'SM06',
                'SM07',
                'SM08',
                'SM09',
                'SM10',
                'SM11',
                'SM12',
                'SM13',
                'SM14',
                'SM15',
                'SM16',
                'SM17',
                'SM18',
                'SM19',
                'SM20',
                'SM21',
                'SM22',
                'SM23',
                'SM24',
                'SM25',
                'SM26',
                'SM27',
                'SM28',
                'SM29',
                'SM30',
                'SM31',
                'SM32',
                'SM33',
                'SM34',
                'SM35',
                'SM36',
                'SM37',
                'SM38',
                'SM39',
                'SM40',
                'SM41',
                'SM42',
                'SM43',
                'SM44',
                'SM45',
                'SM46',
                'SM47',
                'SM48',
                'SM49',
                'SM50',
                'SM51',
                'SM52',
                'SM53',
                'SM54',
                'SM55',
                'SM56',
                'SM57',
                'SM58',
                'SM59',
                'SM60',
                'SM61',
                'SM62',
                'SM63',
                'SM67',
                'SM68',
                'SM71',
                'SM72',
                'SM99',

                'PHARM_A',
                'PHARM_B',
                'PHARM_C',
                'PHARM_D',
                'PHARM_G',
                'PHARM_H',
                'PHARM_EA',
                'PHARM_EB',
                'PHARM_EC',
                'PHARM_ED',
                'PHARM_EG',
                'PHARM_EH'
            ];

            var keys = codes.map(function(code) {
                return "continuumplatformApp.PractitionerSpeciality." + code;
            });

            return $translate(keys).then(function (translations) {
                return codes.map(function(code) {
                    let job;
                    if(code.startsWith('SM')) {
                        job = 'J10';
                    } else if(code.startsWith('PHARM')) {
                        job = 'J21';
                    } else if(code.startsWith('SI')) {
                        job = 'J60';
                    }
                    return ({
                        job: job,
                        code: code,
                        label: translations["continuumplatformApp.PractitionerSpeciality." + code]
                    });
                });
            }, function (translationIds) {
                //?
            });
        };

        return service;
    }
})();
