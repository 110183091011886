(function () {
    'use strict';

    angular
        .module('continuumplatformApp', [
            'continuumplatformApp.idleTimer',
            'continuumplatformApp.referenceData',
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ui.bootstrap',
            'ui.router',
            'ds.objectDiff',
            'angular-loading-bar',
            'ui.select',
            'ngSanitize',
            'angularMoment',
            'angular.filter',
            'chart.js',
            'angular-web-notification',
            'ui-notification',
            'bootstrapLightbox',
            'ui.scroll'
        ])
        .constant('_', window._)
        .config(config)
        .run(run);

    config.$inject = ['$locationProvider'];

    function config($locationProvider) {
        $locationProvider.hashPrefix(''); // backward compatibility TODO remove # with html5 mode
    }

    run.$inject = ['$cookies', '$log', '$rootScope', '$state', '$window', 'stateHandler', 'translationHandler', 'amMoment', 'JhiLanguageService'];

    function run($cookies, $log, $rootScope, $state, $window, stateHandler, translationHandler, amMoment, JhiLanguageService) {
        $rootScope._ = window._;
        $state.defaultErrorHandler(function ($error$) {
            $log.info("Transition error", $error$);
        });

        stateHandler.initialize();
        translationHandler.initialize();

        JhiLanguageService.getCurrent().then(function (lang) {
            amMoment.changeLocale(lang);
        });

        // TODO enable in dev mode
        // $trace.enable('TRANSITION');

        $window.onbeforeunload = function (event) {
            if (event
                && event.target
                && event.target.activeElement
                && event.target.activeElement.tagName.toLowerCase() === 'a'
                && (event.target.activeElement.hasAttribute('download')
                    || event.target.activeElement.href.indexOf('tel:') > -1
                    || event.target.activeElement.href.indexOf('mailto:') > -1)) {
                return;
            }
            if ($rootScope.warnBeforeUnload) {
                return "Attention, les données n'ont pas été sauvegardées. Etes-vous sûr de vouloir sortir ?";
            }
        };

        // eslint-disable-next-line angular/on-watch
        $rootScope.$on('logout', () => {
            if ($window.localStorage && (!$cookies.get('remember-me-enabled') || !$cookies.get('remember-me-enabled') === 'false')) {
                $window.localStorage.removeItem('username');
            }
        });

    }

})();


