(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Authorization', Authorization);

    Authorization.$inject = ['$log', '$state', 'termsConstants', 'Principal', 'Matomo', 'StateSaver', 'IdleTimer'];

    function Authorization($log, $state, termsConstants, Principal, Matomo, StateSaver, IdleTimer) {

        return {authorize};

        function authorize(transition) {

            return Principal.identity().then(authThen);

            function authThen(account) {

                const toState = transition.to();
                const toParams = transition.params();

                const isAuthenticated = Principal.isAuthenticated();

                if(isAuthenticated) {
                    IdleTimer.start();
                }

                // an authenticated user can't access to login
                if (isAuthenticated && toState.name === 'login') {
                    $log.info('Already authenticated. Redirecting to home...');
                    $state.go('home');
                    return false;
                }

                const stateRequiresAuthentication = !toState.data || !toState.data.public;
                if (stateRequiresAuthentication && !isAuthenticated) {
                    // user is not authenticated.
                    // stow the state they wanted before you send them to the login service, so you can return them when you're done
                    StateSaver.storePreviousState(toState.name, toParams);

                    // now, send them to the login state, so they can log in
                    $log.info('Requires authentication. Redirecting to login...');
                    $state.go('login');
                    return false;
                }

                const stateRequiresAuthorization = toState.data && toState.data.authorities && toState.data.authorities.length > 0;
                if (stateRequiresAuthorization && !Principal.hasAnyAuthority(toState.data.authorities)) {
                    $log.warn('Access denied!');
                    $state.go('accessdenied');
                    return false;
                }

                if (isAuthenticated) {

                    if(stateRequiresAuthorization || toState.name === 'home') {

                        // practitioners and patients must accept the latest terms of use
                        if((account.practitioner || account.patient)
                            && toState.name !== 'terms') {
                            let termsVersion = null;
                            if (account.practitioner) {
                                termsVersion = termsConstants.versionPractitioner;
                            } else if (account.patient) {
                                termsVersion = termsConstants.versionPatient;
                            }

                            if (!account.acceptedTermsVersion || account.acceptedTermsVersion < termsVersion) {
                                // stow the state they wanted before you send them to the terms, so you can return them when you're done
                                if (!StateSaver.getPreviousState()) {
                                    StateSaver.storePreviousState(toState.name, toParams);
                                }
                                // go to terms page
                                $log.info('Requires agreement to terms. Redirecting to terms...');
                                $state.go('terms');
                                return false;
                            }
                        }
                    }

                    // recover and clear previousState after external login redirect (e.g. oauth2)
                    if (StateSaver.getPreviousState() && toState.name === 'home') {
                        const previousState = StateSaver.getPreviousState();
                        StateSaver.resetPreviousState();
                        $log.info("Redirecting to stored state", previousState);
                        $state.go(previousState.name, previousState.params);
                    }

                    if (!account.login.endsWith('@continuumplus.net')) {
                        Matomo.init();
                    }
                }

                $log.debug('Authorization succeeded! Transition to', toState.name, 'is allowed.');
                return true;
            }
        }

    }
})();
