(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient', {
                parent: 'patients',
                url: '/patient?search&page&sort',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.patients'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patients.html',
                        controller: 'PatientController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'lastEventDate,desc',
                        squash: true
                    },
                    filters: {
                        value: {
                            healthFacilityId: undefined,
                            facilityDoctorId: undefined,
                            facilityPharmacistId: undefined,
                            nurseCoordId: undefined,
                            birthDate: undefined,
                            ins: undefined,
                            internalId: undefined,
                            givenName: undefined,
                            familyName: undefined,
                        }
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('program');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('healthFacilityType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-search', {
                parent: 'patients',
                url: '/patient-search?internalId',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'continuumplatformApp.patientTherapy.request'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patient-search.html',
                        controller: 'PatientSearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    ins: null,
                    internalId: {
                        value: null,
                        squash: true
                    },
                    givenName: null,
                    familyName: null,
                    birthDate: null,
                },
                resolve: {
                    filters: ['$transition$', ($transition$) => ({
                        ins: null,
                        internalId: $transition$.params().internalId,
                        givenName: null,
                        familyName: null,
                        birthDate: null,
                    })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('gender');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-draft', {
                parent: 'patients',
                url: '/patient-draft',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'continuumplatformApp.patientTherapy.request'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patient-draft.html',
                        controller: 'PatientDraftController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientTherapy');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-detail', {
                parent: 'patients',
                url: '/patient/{id}',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patient-detail.html',
                        controller: 'PatientDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('user');
                        $translatePartialLoader.addPart('audits');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('patient-detail.therapies', {
                url: '/therapies',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patient-therapy-history.html',
                        controller: 'PatientTherapyHistoryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('coordination');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitType');
                        return $translate.refresh();
                    }],
                }
            })
            .state('patient-detail.quality-of-life', {
                url: '/quality-of-life',
                data: {
                    authorities: ['PATIENT_HEALTH_READ'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/qol/patient-quality-of-life.html',
                        controller: 'PatientQualityOfLifeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('qolType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-detail.team', {
                url: '/team',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patient-team.html',
                        controller: 'PatientTeamController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('patient-detail.request-therapy', {
                url: '/request-therapy',
                data: {
                    authorities: ['PATIENT_THERAPY_REQUEST'],
                    pageTitle: 'continuumplatformApp.patientTherapy.request'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patient-therapy-request.html',
                        controller: 'PatientTherapyRequestController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('patient-detail.contacts', {
                url: '/contacts',
                data: {
                    authorities: ['ROLE_PRACTITIONER', 'ROLE_PATIENT'],
                    pageTitle: 'continuumplatformApp.patient.team.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient/patient-contacts.html',
                        controller: 'PatientContactsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", "$transition$", function ($state, $transition$) {
                        return {
                            name: $transition$.from().name || 'home',
                            params: $transition$.params('from')
                        };
                    }]
                }
            })
            .state('patient.delete', {
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_PRACTITIONER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/activity/patient/patient-delete-dialog.html',
                        controller: 'PatientDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Patient', function (Patient) {
                                return Patient.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient', null, {reload: 'patient'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
