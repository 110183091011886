(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('health-facility', {
                parent: 'network',
                url: '/health-facility?page&sort&type&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SECURITY'],
                    pageTitle: 'continuumplatformApp.healthFacility.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/health-facility/health-facilities.html',
                        controller: 'HealthFacilityController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    type: {
                        value: null,
                        squash: true
                    },
                    search: {
                        value: null,
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            type: $stateParams.type,
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('health-facility-detail', {
                parent: 'health-facility',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SECURITY'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/health-facility/health-facility-detail.html',
                        controller: 'HealthFacilityDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitType');

                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'HealthFacility', function ($stateParams, HealthFacility) {
                        return HealthFacility.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
