(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Coordination', Coordination);

    Coordination.$inject = ['$rootScope', '$state'];

    function Coordination($rootScope, $state) {


        const service = {

            newRequest: function(patientId) {
                $state.go('patient-detail.request-therapy', { id: patientId });
                return Promise.resolve('NEW_REQUEST');
            },
        };

        return service;
    }

})();
