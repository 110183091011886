(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ExpiredController', ExpiredController);

    ExpiredController.$inject = ['$scope', '$state', '$log', '$uibModal', 'paginationConstants', 'pagingParams', 'filters', 'ParseLinks', 'Principal', 'Visit', 'HealthFacility', 'Practitioner'];

    function ExpiredController($scope, $state, $log, $uibModal, paginationConstants, pagingParams, filters, ParseLinks, Principal, Visit, HealthFacility, Practitioner) {

        const vm = this;

        vm.filters = filters;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.$onInit = () => {
            loadVisits();
        };

        vm.$onDestroy = () => {

        };

        function loadVisits() {
            Visit.query({
                "status.equals": 'EXPIRED',
                "patientTherapyStatus.in": ['S20_ACTIVE', 'S30_PAUSED'],
                coordinator: true,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ['orderingDate,desc', 'id,desc'],
            }, onSuccess);

        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.visits = data;
            vm.page = pagingParams.page;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
            });
        }

    }
})();
