(function() {
    /*jshint bitwise: false*/
    'use strict';

    // eslint-disable-next-line angular/no-service-method
    angular
        .module('continuumplatformApp')
        .service('FactIcmService', FactIcmService);

    FactIcmService.$inject = [];

    function FactIcmService() {

        const questionsList = [
            {
                id: 'GP1',
                questionGroup: 'BIEN-ÊTRE PHYSIQUE',
                label: 'Je manque d\'énergie',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GP2',
                questionGroup: 'BIEN-ÊTRE PHYSIQUE',
                label: 'J\'ai des nausées',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GP3',
                questionGroup: 'BIEN-ÊTRE PHYSIQUE',
                label: 'À cause de mon état physique, j\'ai du mal à répondre aux besoins de ma famille',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GP4',
                questionGroup: 'BIEN-ÊTRE PHYSIQUE',
                label: 'J\'ai des douleurs',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GP5',
                questionGroup: 'BIEN-ÊTRE PHYSIQUE',
                label: 'Je suis incommodé(e) par les effets secondaires du traitement',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GP6',
                questionGroup: 'BIEN-ÊTRE PHYSIQUE',
                label: 'Je me sens malade',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GP7',
                questionGroup: 'BIEN-ÊTRE PHYSIQUE',
                label: 'Je suis obligé(e) de passer du temps allongé(e)',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GS1',
                questionGroup: 'BIEN-ÊTRE FAMILIAL/SOCIAL',
                label: 'Je me sens proche de mes amis',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GS2',
                questionGroup: 'BIEN-ÊTRE FAMILIAL/SOCIAL',
                label: 'Ma famille me soutient moralement',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GS3',
                questionGroup: 'BIEN-ÊTRE FAMILIAL/SOCIAL',
                label: 'Mes amis me soutiennent',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GS4',
                questionGroup: 'BIEN-ÊTRE FAMILIAL/SOCIAL',
                label: 'Ma famille a accepté ma maladie',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GS5',
                questionGroup: 'BIEN-ÊTRE FAMILIAL/SOCIAL',
                label: 'Je suis satisfait(e) de la communication avec ma famille au sujet de ma maladie',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GS6',
                questionGroup: 'BIEN-ÊTRE FAMILIAL/SOCIAL',
                label: 'Je me sens proche de mon (ma) partenaire (ou de la personne qui est mon principal soutien)',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GS7',
                questionGroup: 'BIEN-ÊTRE FAMILIAL/SOCIAL',
                label: 'Je suis satisfait(e) de ma vie sexuelle',
                indication: 'Quel que soit votre degré d\'activité sexuelle en ce moment, veuillez répondre à la question suivante. Si vous préférez ne pas y répondre, cliquez sur le bouton "Je ne souhaite pas répondre" et passez à la section suivante.',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GE1',
                questionGroup: 'BIEN-ÊTRE ÉMOTIONNEL',
                label: 'Je me sens triste',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GE2',
                questionGroup: 'BIEN-ÊTRE ÉMOTIONNEL',
                label: 'Je suis satisfait(e) de la façon dont je fais face à ma maladie',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GE3',
                questionGroup: 'BIEN-ÊTRE ÉMOTIONNEL',
                label: 'Je perds espoir dans le combat contre ma maladie',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GE4',
                questionGroup: 'BIEN-ÊTRE ÉMOTIONNEL',
                label: 'Je me sens nerveux (nerveuse)',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GE5',
                questionGroup: 'BIEN-ÊTRE ÉMOTIONNEL',
                label: 'Je suis préoccupé(e) par l\'idée de mourir',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GE6',
                questionGroup: 'BIEN-ÊTRE ÉMOTIONNEL',
                label: 'Je suis préoccupé(e) à l\'idée que mon état de santé puisse s\'aggraver',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GF1',
                questionGroup: 'BIEN-ÊTRE FONCTIONNEL',
                label: 'Je suis capable de travailler (y compris le travail à la maison)',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GF2',
                questionGroup: 'BIEN-ÊTRE FONCTIONNEL',
                label: 'Mon travail (y compris le travail à la maison) me donne de la satisfaction',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GF3',
                questionGroup: 'BIEN-ÊTRE FONCTIONNEL',
                label: 'Je suis capable de profiter de la vie',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GF4',
                questionGroup: 'BIEN-ÊTRE FONCTIONNEL',
                label: 'J\'ai accepté ma maladie',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GF5',
                questionGroup: 'BIEN-ÊTRE FONCTIONNEL',
                label: 'Je dors bien',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GF6',
                questionGroup: 'BIEN-ÊTRE FONCTIONNEL',
                label: 'J\'apprécie mes loisirs habituels',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'GF7',
                questionGroup: 'BIEN-ÊTRE FONCTIONNEL',
                label: 'Je suis satisfait(e) de ma qualité de vie actuelle',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'AA1',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Mon épuisement m\'empêche de faire les choses que je veux faire',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'ICM1',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'J\'ai été incommodé(e) par de la diarrhée',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'Hep8',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je ressens une gêne ou des douleurs au ventre',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'Cx6',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'La constipation me gêne',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'AA9',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par des irritations de la peau',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'Lym1',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par des démangeaisons',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'ICM2',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par la sécheresse de ma peau',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'ICM3',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par le vitiligo (apparition de tâches blanches sur ma peau)',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'Br20',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je ressens une faiblesse dans les bras et les jambes',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'ICM4',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je ressens des douleurs ou des courbatures dans certains de mes muscles',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'BRM1',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'J\'ai des douleurs articulaires',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'AA10',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par des gonflements dans certaines parties de mon corps',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'BMT13',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis gêné(e) par un changement de goût des aliments',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'Ga1',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Mon appétit a diminué',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'O2',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'J\'ai des vomissements',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'B1',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'J\'ai le souffle court',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'L2',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je tousse',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'BRM3',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par de la fièvre',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'MS3',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis embêté(e) par des maux de tête',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'Lym2',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'J\'ai du mal à dormir la nuit',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'BRM5',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par ma sécheresse buccale',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'NP3',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par la détérioration de ma vue',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'ICM6',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je suis incommodé(e) par des réactions de courte durée au traitement qui surviennent immédiatement après une perfusion ou dans les 24 heures (p.ex., des frissons, des étourdissements, de l\'urticaire, des irritations de la peau qui ne durent pas plus de 24 heures)',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'ICM7',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Cela m\'inquiète de ne pas savoir quand exactement mes effets secondaires se produiront, combien de temps ils dureront et à quel point ils seront graves',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
            {
                id: 'ICM5',
                questionGroup: 'AUTRES SUJETS D\'INQUIÉTUDE',
                label: 'Je m\'inquiète des effets négatifs que mon traitement pourrait avoir sur ma santé à long terme',
                type: 'RATING',
                possibleScores: [{ value: 0, placeholder: 'Pas du tout' }, { value: 1, placeholder: 'Un peu' }, { value: 2, placeholder: 'Moyennement' }, { value: 3, placeholder: 'Beaucoup' }, { value: 4, placeholder: 'Énormément' }],
            },
        ];

        this.questions = questionsList;
        this.calculateScore = calculateScore;
        this.getQuestionsGroupByGroup = getQuestionsGroupByGroup;

        function calculateScore(questions) {
            const scores = {
                ADDITIONAL_CONCERNS: calculateAdditionalConcernsScore(questions),
                PHYSICAL_WELL_BEING: calculatePhysicalWellBeingScore(questions),
                SOCIAL_FAMILY_WELL_BEING: calculateSocialFamilyWellBeingScore(questions),
                EMOTIONAL_WELL_BEING: calculateEmotionalWellBeingScore(questions),
                FUNCTIONAL_WELL_BEING: calculateFunctionalWellBeingScore(questions),
                TOTAL_FACT_G_SCORE: null,
                TOTAL_FACT_ICM_SCORE: null,
            };

            scores.TOTAL_FACT_G_SCORE = scores.PHYSICAL_WELL_BEING + scores.SOCIAL_FAMILY_WELL_BEING + scores.EMOTIONAL_WELL_BEING + scores.FUNCTIONAL_WELL_BEING;
            scores.TOTAL_FACT_ICM_SCORE = scores.TOTAL_FACT_G_SCORE + scores.ADDITIONAL_CONCERNS;

            return scores;
        }

        function calculateAdditionalConcernsScore(questions) {
            const subscaleQuestions = questions.filter((q) => q.questionGroup === 'AUTRES SUJETS D\'INQUIÉTUDE');
            const subscaleQuestionsWithAnswer = subscaleQuestions.filter((q) => q.score >= 0);

            let sumIndividualItemScores = 0;
            subscaleQuestionsWithAnswer.forEach((q) => {
                sumIndividualItemScores += (4 - q.score);
            });

            return (sumIndividualItemScores * 25) / subscaleQuestionsWithAnswer.length;
        }

        function calculatePhysicalWellBeingScore(questions) {
            const subscaleQuestions = questions.filter((q) => q.questionGroup === 'BIEN-ÊTRE PHYSIQUE');
            const subscaleQuestionsWithAnswer = subscaleQuestions.filter((q) => q.score >= 0);

            let sumIndividualItemScores = 0;
            subscaleQuestionsWithAnswer.forEach((q) => {
                sumIndividualItemScores += (4 - q.score);
            });

            return (sumIndividualItemScores * 7) / subscaleQuestionsWithAnswer.length;
        }

        function calculateSocialFamilyWellBeingScore(questions) {
            const subscaleQuestions = questions.filter((q) => q.questionGroup === 'BIEN-ÊTRE FAMILIAL/SOCIAL');
            const subscaleQuestionsWithAnswer = subscaleQuestions.filter((q) => q.score >= 0);

            let sumIndividualItemScores = 0;
            subscaleQuestionsWithAnswer.forEach((q) => {
                sumIndividualItemScores += q.score;
            });

            return (sumIndividualItemScores * 7) / subscaleQuestionsWithAnswer.length;
        }

        function calculateEmotionalWellBeingScore(questions) {
            const subscaleQuestions = questions.filter((q) => q.questionGroup === 'BIEN-ÊTRE ÉMOTIONNEL');
            const subscaleQuestionsWithAnswer = subscaleQuestions.filter((q) => q.score >= 0);

            let sumIndividualItemScores = 0;
            subscaleQuestionsWithAnswer.forEach((q) => {
                if (q.id === 'GE2') {
                    sumIndividualItemScores += q.score;
                } else {
                    sumIndividualItemScores += (4 - q.score);
                }
            });

            return (sumIndividualItemScores * 6) / subscaleQuestionsWithAnswer.length;
        }

        function calculateFunctionalWellBeingScore(questions) {
            const subscaleQuestions = questions.filter((q) => q.questionGroup === 'BIEN-ÊTRE FONCTIONNEL');
            const subscaleQuestionsWithAnswer = subscaleQuestions.filter((q) => q.score >= 0);

            let sumIndividualItemScores = 0;
            subscaleQuestionsWithAnswer.forEach((q) => {
                sumIndividualItemScores += q.score;
            });

            return (sumIndividualItemScores * 7) / subscaleQuestionsWithAnswer.length;
        }

        function getQuestionsGroupByGroup() {
            return [
                questionsList.filter((q) => q.questionGroup === 'BIEN-ÊTRE PHYSIQUE'),
                questionsList.filter((q) => q.questionGroup === 'BIEN-ÊTRE FAMILIAL/SOCIAL'),
                questionsList.filter((q) => q.questionGroup === 'BIEN-ÊTRE ÉMOTIONNEL'),
                questionsList.filter((q) => q.questionGroup === 'BIEN-ÊTRE FONCTIONNEL'),
                questionsList.filter((q) => q.questionGroup === 'AUTRES SUJETS D\'INQUIÉTUDE'),
            ];
        }

    }
})();


