(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$rootScope', '$scope', '$state', 'Auth', 'Principal', '$uibModal', '$document', '$log', '$timeout', 'HealthFacility'];

    function SidebarController($rootScope, $scope, $state, Auth, Principal, $uibModal, $document, $log, $timeout, HealthFacility) {
        const vm = this;

        vm.$state = $state;

        (function ($, viewport) {
            $(document).ready(() => {
                initSidebar($, viewport);
            });
        })(jQuery, ResponsiveBootstrapToolkit);

        vm.unsubscribeAccountSelfUpdate = $rootScope.$on('continuumplatformApp:accountSelfUpdate', (event, account) => {
            vm.isPractitionerAway = isPractitionerAway(account.practitioner);
        });

        vm.$onInit = () => {
            getAccount();
        };

        vm.$onDestroy = () => {
            if (vm.unsubscribeAccountSelfUpdate) {
                vm.unsubscribeAccountSelfUpdate();
            }
        };

        function getAccount() {
            Principal.identity().then(account => {

                vm.account = account;
                vm.isPatient = account.authorities && account.authorities.includes('ROLE_PATIENT');
                vm.isAllowedToReadPatientHealthData = account.authorities && account.authorities.includes('PATIENT_HEALTH_READ');
                vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');
                vm.hasRoleEditor = account.authorities && account.authorities.includes('ROLE_EDITOR');
                vm.isCrpvUser = account.practitioner && account.practitioner.healthFacilityType === 'PV';

                if (account.practitioner) {
                    vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                    vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';
                    vm.isCrpvUser = account.practitioner.healthFacilityType === 'PV';

                    vm.isDoctor = account.practitioner.job === 'J10';
                    vm.isPharmacist = account.practitioner.job === 'J21';
                    vm.isPharmacyTechnician = account.practitioner.job === 'J38';
                    vm.isNurse = account.practitioner.job === 'J60';
                    vm.isOtherPractitioner = account.practitioner.job === 'J99';

                    if (account.practitioner.healthFacilityId) {
                        HealthFacility.get({id: account.practitioner.healthFacilityId, view: 'summary'}, function (healthFacility) {
                            vm.healthFacility = healthFacility;

                            vm.pecam = vm.healthFacility.features.includes('PECAM');
                        });
                    }

                    vm.isPractitionerAway = isPractitionerAway(account.practitioner);
                }

            });
        }

        function initSidebar($, viewport) {
            $timeout(function () {
                $('[data-widget="tree"]').tree();
                $('.sidebar-menu a[ui-sref]').click(function () {
                    if (viewport.is('xs')) {
                        $('[data-toggle="push-menu"]').pushMenu('toggle');
                    }
                });
            });
        }

        function isPractitionerAway(practitioner) {
            const today = moment().format('yyyy-MM-DD');
            return (!!practitioner.leaveFrom && practitioner.leaveTo >= today) || (!!practitioner.leaveFrom && !practitioner.leaveTo);
        }

    }
})();
