(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SettingsPractitionerDetailController', SettingsPractitionerDetailController);

    SettingsPractitionerDetailController.$inject = ['practitioner'];

    function SettingsPractitionerDetailController(practitioner) {
        const vm = this;

        vm.practitioner = practitioner;

    }
})();
