(function () {
    'use strict';

    var visitsTable = {
        bindings: {
            visits: '=',
            thead: '=',
            showPatient: '=',
            showPractitioner: '=',
            hideAction: '=',
        },
        controller: VisitsTableController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visits-table.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitsTable', visitsTable);

    VisitsTableController.$inject = ['$rootScope', '$state', '$uibModal', 'Visit'];

    function VisitsTableController($rootScope, $state, $uibModal, Visit) {

        const vm = this;

        vm.isAllowed = Visit.isAllowed;
        vm.hasHiddenPast = 0;
        vm.hasHiddenFuture = 0;
        vm.hasShown = null;

        vm.$onInit = () => {
            vm.visits = vm.visits.map(v => {
                if(v.hiddenPast) {
                    vm.hasHiddenPast++;
                }
                if(v.hiddenFuture) {
                    vm.hasHiddenFuture++;
                }
                if(!v.hiddenPast && !v.hiddenFuture) {
                    vm.hasShown = true;
                }
                return v;
            });
            if(!vm.hasShown === null) {
                vm.hasShown = false;
            }
        };

        vm.showPastVisits = () => {
            vm.visits = vm.visits.map(v => {
                v.hiddenPast = false;
                return v;
            });
            vm.hasHiddenPast = 0;
            vm.hasShown = true;
        };

        vm.showFutureVisits = () => {
            vm.visits = vm.visits.map(v => {
                v.hiddenFuture = false;
                return v;
            });
            vm.hasHiddenFuture = 0;
            vm.hasShown = true;
        };

        vm.plan = (visit, index) => {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: ['Visit', function (Visit) {
                        return Visit.get({id: visit.id}).$promise;
                    }],
                }
            }).result.then((updatedVisit) => {
                // on met à jour la visite dans la liste pour refléter les changements sur l'affichage
                vm.visits.splice(index, 1, updatedVisit);
            }, () => {
                // do nothing
            });
        };

    }

})();
