(function() {
    /*jshint bitwise: false*/
    'use strict';

    // eslint-disable-next-line angular/no-service-method
    angular
        .module('continuumplatformApp')
        .service('PatientQualityOfLifeService', PatientQualityOfLifeService);

    PatientQualityOfLifeService.$inject = ['Visit'];

    function PatientQualityOfLifeService(Visit) {

        this.loadVisitWithQol = loadVisitWithQol;

        function loadVisitWithQol(patientId) {
            return Visit.query({
                "patientId.equals": patientId,
                "status.equals": 'DONE',
                "sort": "date,desc",
                "size": 1000
            }).$promise.then((visits) => {
                if (visits) {
                    return {
                        'QOL_FACT_ICM': visits.filter(v => v.steps && v.steps.includes('QOL_FACT_ICM')),
                    };
                } else {
                    return {};
                }
            });
        }
    }
})();


