(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HelpController', HelpController);

    HelpController.$inject = ['$scope', 'Principal', 'termsConstants', 'HealthFacility', 'PatientTherapy'];

    function HelpController($scope, Principal, termsConstants, HealthFacility, PatientTherapy) {
        const vm = this;

        vm.termsUrl = '';

        vm.account = null;

        vm.$onInit = () => {
            getAccount();
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                if (account) {
                    vm.isAuthenticated = true;
                    vm.account = account;

                    vm.isPractitioner = account && account.practitioner;

                    // on détermine les CGU à afficher en fonction de l'utilisateur
                    if (account && account.practitioner) {
                        vm.termsUrl = termsConstants.attachmentUrlPractitioner;
                    }

                    if (vm.account.practitioner) {
                        if (['HOSPITAL', 'PHARMACY'].includes(vm.account.practitioner.healthFacilityType)) {
                            HealthFacility.get({
                                id: vm.account.practitioner.healthFacilityId,
                                view: 'summary'
                            }, function (healthFacility) {
                                vm.healthFacility = healthFacility;
                            });
                            // TODO help?
                        } else {
                            // TODO help?
                        }
                    }
                } else {
                    vm.isAuthenticated = false;
                }
            });
        }

    }
})();
