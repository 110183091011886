(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyDialogController', PatientTherapyDialogController);

    PatientTherapyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', 'entity', 'PatientTherapy', 'Therapy', 'Principal', 'Visit'];

    function PatientTherapyDialogController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, entity, PatientTherapy, Therapy, Principal, Visit) {

        const vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.patientTherapy = entity;
        vm.form = {
            day1Date: entity.day1Date,
            assigneeId: entity.assignee && entity.assignee.id,
            singleOverride: entity.singleOverride
        };

        vm.visitFrequencies = Visit.getFrequencies();

        loadTherapies();

        PatientTherapy.findAvailableCoordinators(vm.patientTherapy).then(coordinators => {
            vm.coordinators = coordinators;
        });

        Principal.identity().then(function (account) {
            vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.$onInit = () => {
            if (vm.patientTherapy.status === 'S11_VALIDATED') {
                vm.form.therapyId = entity.therapyId;
                vm.unWatchTherapyId = $scope.$watch("vm.form.therapyId", onTherapyIdChange);
                vm.unWatchSingleOverride = $scope.$watch('vm.form.singleOverride', onSingleOverrideChange, true);
            } else {
                loadTherapy(entity.therapyId);
            }
        };

        vm.$onDestroy = () => {
            if (vm.unWatchTherapyId) {
                vm.unWatchTherapyId();
            }
            if (vm.unWatchSingleOverride) {
                vm.unWatchSingleOverride();
            }
        };

        function onTherapyIdChange(newVal, oldVal) {
            if (newVal) {
                loadTherapy(newVal);
            } else {
                vm.therapy = null;
                vm.form.singleOverride = null;
            }
        }

        function onSingleOverrideChange(newVal, oldVal) {
            if (newVal && newVal.limitDays < newVal.reminderDay) {
                vm.form.singleOverride.reminderDay = newVal.limitDays - 1;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            PatientTherapy.update({id: vm.patientTherapy.id}, vm.form, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:patientTherapyUpdate');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function loadTherapies() {
            vm.therapies = [];
            return Therapy.query({
                size: 999,
                'status.equals': 'ACTIVE',
                'sort': 'code',
            }).$promise.then(result => {
                vm.therapies = result;

                if (vm.therapies.size === 1) {
                    vm.form.therapyId = vm.therapies[0].id;
                }
                vm.loadedTherapies = true;
            });
        }

        function loadTherapy(id) {
            Therapy.get({id}, therapy => {
                vm.therapy = therapy;

                if (vm.therapy.singleRenewed) {
                    const singleVisit = vm.therapy.plan.visits[0];
                    vm.form.singleOverride = {
                        frequency: singleVisit.frequency,
                        reminderDay: singleVisit.reminderDay,
                        limitDays: singleVisit.limitDays,
                    };
                    if (vm.patientTherapy.singleOverride) {
                        if (vm.patientTherapy.singleOverride.frequency) {
                            vm.form.singleOverride.frequency = vm.patientTherapy.singleOverride.frequency;
                        }
                        if (vm.patientTherapy.singleOverride.reminderDay !== null) {
                            vm.form.singleOverride.reminderDay = vm.patientTherapy.singleOverride.reminderDay;
                        }
                        if (vm.patientTherapy.singleOverride.limitDays !== null) {
                            vm.form.singleOverride.limitDays = vm.patientTherapy.singleOverride.limitDays;
                        }
                    }
                } else {
                    vm.form.singleOverride = null;
                }
            });
        }

    }
})();
